export const environment = {
  production: true,
  enableDebug: true,
  name: 'test',
  core_server: window['env']['CORE_SERVER'],
  websocket_host: window['env']['WEBSOCKET_HOST'],
  serverTransfo: window['env']['TRANSFO_SERVER'],
  report_server: window['env']['REPORT_SERVER'],
  version: window['env']['VERSION'],
  storageServer: window['env']['STORAGE_SERVER'],
};
