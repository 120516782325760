import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.access_token) {
      if (!request.headers.get('Authorization')) {
        request = request.clone({
          setHeaders: {
            // Authorization: 'Basic ' + btoa('amina:amina1243')
            // Authorization: `Bearer 556c03fc76c7e5d2a349a185b64b8a40c40dddba8be98d97cbb9acb9d87a05a8`
            Authorization: `Bearer ${currentUser.access_token}`,
          },
        });
      }
    }
    return next.handle(request);
  }
}
