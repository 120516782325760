import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthService {
  private authServer = environment.core_server;

  constructor(private http: HttpClient, private permissionsService: NgxPermissionsService) {}

  login(username: string, password: string) {
    const httpOptions = {
      headers: new HttpHeaders({}),
    };
    return this.http
      .post<any>(
        this.authServer + 'users/login',
        {
          username: username,
          password: password,
        },
        httpOptions,
      )
      .pipe(
        map((user) => {
          if (user) {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.permissionsService.loadPermissions(user.user.permissions);
          }
          return user;
        }),
      );
  }

  checkToken(token: string) {
    return this.http.get<any>(this.authServer + 'users/token/info');
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.permissionsService.flushPermissions();
  }

  aboutCore() {
    let urlPath = environment.core_server.split('/');
    urlPath = urlPath[0] + '//' + urlPath[2];
    return this.http.get<any>(urlPath + '/info');
  }

  aboutTransfo() {
    return this.http.get<any>(environment.serverTransfo + '/info');
  }
}
