<div class="login-container text-center">
  <div class="form-signin">
    <img class="mb-4" src="../../../assets/img/Picto-Akilee-05.png" alt="" width="120" />
    <h1 class="h3 mb-3 header-title">Scota BackOffice</h1>
    <h1 class="h3 mb-3 font-weight-normal">Veuillez vous identifier</h1>
    <div class="form-label-group">
      <input
        type="text"
        id="inputEmail"
        name="username"
        [(ngModel)]="username"
        class="form-control"
        placeholder="Identifiant"
        required
        autofocus
      />
      <label for="inputEmail">Identifiant</label>
    </div>
    <div class="form-label-group">
      <input
        type="password"
        id="inputPassword"
        name="password"
        [(ngModel)]="password"
        class="form-control"
        placeholder="Mot de passe"
        required
        (keydown.enter)="onSubmit()"
      />
      <label for="inputPassword">Mot de passe</label>
    </div>

    <button class="btn btn-lg btn-akilee btn-block" type="submit" (click)="onSubmit()" [disabled]="loading">
      <span *ngIf="!loading;else waiting">Se connecter</span>
    </button>
    <div style="color: whitesmoke;font-size: xx-small;">
      {{this.aboutApp + ' | ' + this.aboutCore?.version + ' | ' + this.aboutTransfo?.version}}
    </div>
  </div>
  <ngx-loading
    [show]="loading"
    [config]="{ backdropBorderRadius: '3px' }"
  ></ngx-loading>
</div>
<ng-template #waiting>Connexion...</ng-template>
