import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../../admin/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  username: string;
  password: string;
  aboutCore = {version: ''};
  aboutTransfo = {version: ''};
  aboutApp;
  loading = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationsService,
  ) {
    this.aboutApplications();
  }

  ngOnInit() {
    this.authService.logout();
  }

  onSubmit() {
    this.loading = true;
    this.authService
      .login(this.username, this.password)
      .pipe(first())
      .subscribe(
        (user) => {
          this.router.navigate(['/admin/reports/week']);
        },
        () => {
          this.notificationService.error('Erreur', 'Identifiants incorrects', {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: false,
            animate: 'fromRight',
          });
          this.password = '';
          this.loading = false
        },
      );
  }

  aboutApplications() {
    this.aboutApp = environment.version;
    this.authService.aboutCore().subscribe((data) => {
      this.aboutCore = data;
    });
    this.authService.aboutTransfo().subscribe((data) => {
      this.aboutTransfo = data;
    });
  }
}
