import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../admin/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private authService: AuthService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (window.localStorage) {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser && currentUser.user) {
        return this.authService.checkToken(currentUser.access_token).pipe(
          map((user) => {
            if (user) {
              localStorage.setItem('currentUser', JSON.stringify(user));
              this.permissionsService.loadPermissions(user.user.permissions);
              const expireDate = new Date((user.created_at + user.expires_in) * 1000);
              const nowDate = new Date();
              if (expireDate > nowDate) {
                return true;
              } else {
                this.router.navigate(['/login']);
                return false;
              }
            }
            this.router.navigate(['/login']);
            return false;
          }),
          catchError(() => {
            this.router.navigate(['/login']);
            return of(false);
          }),
        );
        // return true;
      }
    }
    this.router.navigate(['/login']);
    return false;
  }
}
